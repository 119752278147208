import {
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
import {
  getListAgent,
  deleteDataAgen,
  editIsActiveAgent
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import {
  useRouter, useRoute
} from 'vue-router'

export default {
  setup() {
    const router = useRouter()
    const store = useStore()
    const dataAgent = ref('')
    const filter = {
      offset: 0,
      top: 10,
      skip: 0,
      expand: '&$expand=Kota($select=Id,Nama), TipeAgen($select=Id,Nama)'
    }
    const totalRecords = ref(0)
    const sliderActive = ref(false)
    const inputSearch = ref(null)
    const sendInputSearch = ref('')
    const dataForm = ref(null)
    const op = ref()
    const app = getCurrentInstance()
    const route = useRoute()

    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties

    const getAgent = async () => {
      store.dispatch('showLoading')
      const response = await getListAgent({
        search: sendInputSearch.value,
        top: filter.top,
        skip: filter.skip,
        expand: filter.expand
      })
      // console.log('reposnse agent', response)
      totalRecords.value = response.count
      dataAgent.value = response.result
      store.dispatch('hideLoading')
    }
    const onPage = (event: any) => {
      filter.skip = event.page * filter.top
      getAgent()
    }
    const searchData = async (val: any) => {
      // console.log('search Data', val)
      sendInputSearch.value = val.toLowerCase()
      await getAgent()
    }
    const redirectAdd = () => {
      router.push({
        name: 'master-agent-tambah'
      })
    }
    const showDropDown = (evt: any, data: any) => {
      // console.log('masuk click drop', data, evt)
      dataForm.value = data
      op.value.toggle(evt)
    }
    const editData = (data: any) => {
      // console.log('data edit', router)
      const IdAgent = data.Id
      router.push({
        name: 'master-agent-edit',
        params: {
          agentId: IdAgent
        }
      })
    }
    const deleteData = (Id: number) => {
      $confirm.require({
        header: 'Agent',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await deleteDataAgen(Id)
          if (response.error) {
            $toast.add({
              severity: 'error',
              detail: $strInd.toast.errorDelete,
              group: 'bc',
              life: 3000
            })
          } else {
            $toast.add({
              severity: 'success',
              detail: $strInd.toast.successDeleteData,
              group: 'bc',
              life: 3000
            })
            getAgent()
          }
        }
      })
    }
    const isActived = async (val: any) => {
      store.dispatch('showLoading')
      const response = await editIsActiveAgent({
        Id: val.Id,
        IsActive: val.IsActive
      })

      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: 'Berhasil merubah status aktif', group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const changeIsActive = (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: 'Apakah anda ingin menggantinya?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          isActived(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }

    onMounted(() => {
      getAgent()
    })
    return {
      dataAgent,
      getAgent,
      filter,
      store,
      totalRecords,
      onPage,
      sliderActive,
      inputSearch,
      sendInputSearch,
      searchData,
      router,
      redirectAdd,
      dataForm,
      op,
      showDropDown,
      editData,
      deleteData,
      changeIsActive,
      app,
      route
    }
  }
}